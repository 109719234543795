import fetch from "./xhr/index";

/**
 * 报价单-报价完成-发起改价
 * @param
 */
export const commitChangePrice= (param) => fetch("POST", "/quoted/quotePriceChange/changePrice", param);

/**
 * 报价单-报价完成-改价记录
 * @param
 */
export const changePriceLog = (param) => fetch("GET", "/quoted/quotePriceChange/changeLog", param);

/**
 * 报价单-报价完成-详情
 * @param
 */
export const quotedSuccess = (param) => fetch("GET", "/quoted/quotedPriceCustomerOne/priceDetail", param);

/**
 * 报价单-确认报价-保存
 * @param
 */
export const saveConfirmQuoted = (param) => fetch("GET", "/quoted/quotedDataStandardsFour/finalDetailSave", param);

/**
 * 报价单-确认报价-详情
 * @param
 */
export const confirmQuoted = (param) => fetch("GET", "/quoted/quotedDataStandardsFour/finalDetail", param);

/**
 * 报价单-约定数据查询-保存
 * @param
 */
export const saveAgreedData = (param) => fetch("POST", "/quoted/quotedDataStandardsFour/save", param);

/**
 * 报价单-约定数据查询-详情
 * @param
 */
export const agreedData = (param) => fetch("GET", "/quoted/quotedDataStandardsFour/detail", param);

/**
 * 报价单-配置基础服务内容-计算价格
 * @param
 */
export const calculationPrice = (param) => fetch("GET", "/quoted/quotedPriceCustomerOne/calculation", param);

/**
 * 报价单-配置基础服务内容-保存
 * @param
 */
export const saveSetContent = (param) => fetch("POST", "/quoted/quotedConfigureContentThree/save", param);

/**
 * 报价单-配置基础服务内容-详情
 * @param
 */
export const setContentDetail = (param) => fetch("GET", "/quoted/quotedConfigureContentThree/detail", param);

/**
 * 报价列表
 * @param
 */
export const priceList = (param) => fetch("POST", "/quoted/quotedPriceCustomerOne/priceList", param);

/**
 * 客户报价保存
 * @param
 */
export const saveCus = (param) => fetch("POST", "/quoted/quotedPriceCustomerOne/save", param);


/**
 * 报价客户信息
 * @param
 */
export const cusInfo = (param, routeParam) => fetch("GET", `/quoted/quotedPriceCustomerOne/detail?id=${routeParam}`, param);

/**
 * 基础服务填报查询
 * @param
 */
export const baseSerach = (param, routeParam) => fetch("GET", `/quoted/quotedBaseServiceTwo/detail?id=${routeParam}`, param);

/**
 * 客户关联店铺
 * @param
 */
export const relationShop = (param, routeParam) => fetch("GET", `/quoted/quotedPriceCustomerOne/relationShop?id=${routeParam}`, param);


/**
 * 基础服务填报保存
 * @param
 */
export const baseSave = (param) => fetch("POST", "/quoted/quotedBaseServiceTwo/save", param);

/**
 * 获取报价单价
 * @param
 */
export const getPrice = (param) => fetch("GET", "/quoted/quotedBaseServiceTwo/costPrice", param);


/**
 * 报价单-查询客户报表详情
 * @param
 */
export const offerCustomer = (param) => fetch("GET", "/cms/cmsCustomer/getOfferCustomer", param);


/**
 * 客户报价计算
 * @param
 */
export const total = (param, routeParam) => fetch("GET", `/quoted/quotedPriceCustomerOne/calculation?id=${routeParam}`, param);