<!-- 配置服务内容 -->
<template>
  <div class="setting-container">
    <header-area :stepActive="2"></header-area>
    <div class="borderTop" v-for="(item, i) in conList" :key="i">
      <div class="con-tit">
        <div style="display: flex;align-items: center;">
          <span class="tit-index">{{ i + 1 }}</span>
          <img v-if="item.shopData.shopLogo" :src="item.shopData.shopLogo" class="tit-img" />
        </div>
        <div class="titHeader">
          <div style="display: flex;align-items: center;">
            <span class="tit-name">{{item.shopData.shopName}}</span>
            <el-tag type="success" v-if="item.shopData.serviceType == 1" class="tit-tag">{{'普通客服'}}</el-tag>
            <el-tag type="success" v-if="item.shopData.serviceType == 2" class="tit-tag blue">{{'金牌客服'}}</el-tag>
            <el-tag type="success" v-if="item.shopData.serviceType == 3" class="tit-tag orange">{{'临时客服'}}</el-tag>
          </div>
          <div class="shop-web-platform">
            <!-- <img src="item" class="shop-icon" /> -->
            <span class="tit-de">{{item.shopData.platformName}}</span>
            <span class="website">{{item.shopData.shopUrl}}</span>
          </div>
        </div>
        <div class="tit-childrenNum" style="display: flex;">
          <div class="tit-child">
            <span>协议客服工作时长</span>
            <span class="tit-num">{{item.shopData.workHour}}</span>
          </div>
          <div class="tit-child">
            <span>协议子账号数量</span>
            <span class="tit-num">{{item.shopData.subaccountNum}}</span>
          </div>
          <div class="tit-child2">
            <span>协议子账号日咨询量</span>
            <span class="tit-num">{{item.shopData.shopConsultationNum}}</span>
          </div>
          <div class="titDays">
            <span class="tit-days">{{item.shopData.serviceDay || 0}}天</span>
            <span class="tit-date">{{item.shopData.startTime}} ~ {{item.shopData.endTime}}</span>
          </div>
        </div>
      </div>
      <div class="con-body">
        <!-- 售前 -->
        <div v-for="(itemTwo, indexTwo) in item.serviceContent" :key="indexTwo" :class="{'body-item': true, 'body-item-grey': !itemTwo.isChoose}">
          <div class="one-tit">
            <span class="one-tit-header">【{{itemTwo.step == 0 ? '售前' :itemTwo.step == 1 ? '售中':itemTwo.step == 2 ? '售后': '附加类'}}】服务内容</span>
            <el-switch v-model="itemTwo.isChoose" @change="(val) => { switchFun(val, indexTwo, i) }" active-color="#1890FF" inactive-color="#ff4949"></el-switch>
          </div>
          <div class="one-con">
            <ul>
              <li>
                <span class="li-one">目录</span
                ><span class="li-two">基础功能</span
                ><span class="li-three">登记</span
                ><span class="li-four">通知</span>
              </li>
              <li v-for="(itemThree, indexThree) in itemTwo.value" :key="indexThree" class="li-item">
                <div>
                  <span class="li-one">{{itemThree.name}}</span>
                  <span class="li-two twoColor"><i class="el-icon-check"></i></span>
                  <span class="li-three"><el-checkbox :disabled="!itemTwo.isChoose" @change="() => { saveSetContent('calculation') }" v-model="itemThree.isRegist"></el-checkbox></span>
                  <span class="li-four"><el-checkbox :disabled="!itemTwo.isChoose" @change="() => { saveSetContent('calculation') }" v-model="itemThree.isNotice"></el-checkbox></span>
                </div>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <div class="sixth">
      <el-button @click="() => { resetDialog = true }">重置此页</el-button>
      <div style="display: flex;align-items: center">
        <span class="sett-money">配置费用：</span>
        <span class="sett-rmb">¥</span>
        <span class="sett-total">{{(price).toFixed(2)}}</span>
        <!-- <el-button type="primary" @click="() => { saveSetContent('calculation') }">计算价格</el-button> -->
        <!-- <span v-if="true" class="sett-status">(待核算)</span>
        <span v-else class="sett-status">(正在计算价格...)</span> -->
        <el-button plain class="sett-return" @click="() => { $router.push(`/quoted/base-services?id=${$route.query.id}`) }">上一步：客户报价</el-button>
        <!-- <el-button plain class="sett-return" @click="() => { this.$router.push({ path: '/quoted/base-services',query: {id: this.$route.query.id,name: 'gobase'} }) }">上一步：客户报价</el-button> -->
        <el-button type="primary" @click="() => { saveSetContent() }">下一步：配置服务内容</el-button>
      </div>
    </div>
    <el-dialog v-dialogDrag title="重置" :append-to-body="true" width="530px" :visible.sync="resetDialog" :close-on-click-modal="false">
        <div>确认重置已填写的数据吗？</div>
        <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="resetDialog = false">取 消</el-button>
            <el-button class="common-screen-btn" type="primary" @click="() => { resetDetail() }">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { setContentDetail, saveSetContent, calculationPrice } from "../../service/quoted.js"
import HeaderArea from "./components/header.vue"
export default {
  name: "settingContent",
  components: {
    HeaderArea
  },
  props: {},
  data() {
    return {
      conList: [],
      conListSource: [], // 源数据详情
      price: 0,
      value: true,
      checked: true,
      resetDialog: false,
      cusId: this.$route.query.cusId, //查询的客户ID
    };
  },
  created() {
    
  },
  mounted() {
    this.setContentDetail()
    this.calculationPrice()
  },
  methods: {
    async calculationPrice () {
      let { data } = await calculationPrice({id: this.$route.query.id})
        this.price = data.price || 0
    },
    switchFun (value, iTwo, iOne) { // 开关
      let conList = JSON.parse(JSON.stringify(this.conList))
      if (!value) {
        conList.map((item, indexOne) => {
          if (item.serviceContent && iOne == indexOne) {
            item.serviceContent.map((itemTwo, indexTwo)=>{
              if (indexTwo == iTwo) {
                itemTwo.value.map((itemThree)=>{
                  itemThree.isNotice = false
                  itemThree.isRegist = false
                })
              }
            })
          }
        })
      } else {
        conList.map((item, indexOne) => {
          if (item.serviceContent && iOne == indexOne) {
            item.serviceContent.map((itemTwo, indexTwo)=>{
              if (indexTwo == iTwo) {
                itemTwo.value.map((itemThree)=>{
                  itemThree.isNotice = true
                  itemThree.isRegist = true
                })
              }
            })
          }
        })
      }
      this.conList = conList
      this.saveSetContent('calculation')
    },
    async saveSetContent (type) { // 保存配置内容
      let conList = JSON.parse(JSON.stringify(this.conList))
      let shopData = []
      conList.map((item) => {
        if (item.serviceContent) {
          item.serviceContent.map((itemTwo)=>{
            itemTwo.isChoose = itemTwo.isChoose ? 1 : 0
            itemTwo.value.map((itemThree)=>{
              itemThree.isNotice = itemThree.isNotice ? 1 : 0
              itemThree.isRegist = itemThree.isRegist ? 1 : 0
            })
          })
          item.serviceContent = JSON.stringify(item.serviceContent)
          shopData.push({configValue: item.serviceContent, baseServiceShopId: item.shopData.id, configContentId: item.configContentId})
        }
      })
      await saveSetContent({shopData: shopData})
      if (type == 'calculation') {
        let { data } = await calculationPrice({id: this.$route.query.id})
        this.price = data.price || 0
      } else {
        this.$router.push(`/quoted/appoint?id=${this.$route.query.id}`)
      }
    },
    resetDetail () { // 重置数据
      let conList = [...this.conList]
      conList.map((item) => {
        if (item.serviceContent) {
          item.serviceContent.map((itemTwo) => {
            itemTwo.isChoose = false
            if (itemTwo.value) {
              itemTwo.value.map((itemThree) => {
                itemThree.isRegist = false
                itemThree.isNotice = false
              })
            }
          })
        }
      })
      this.conList = conList
      this.saveSetContent('calculation')
      this.resetDialog = false
      // this.conList = JSON.parse(JSON.stringify(this.conListSource))
      // this.saveSetContent('calculation')
    },
    async setContentDetail () { // 获取配置服务内容
      let { data } = await setContentDetail({id: this.$route.query.id})
      data.map((item) => {
        if (item.serviceContent) {
          item.serviceContent = JSON.parse(item.serviceContent)
          item.serviceContent.map((itemTwo)=>{
            itemTwo.isChoose = itemTwo.isChoose == 0 ? false : true
            itemTwo.value.map((itemThree)=>{
              itemThree.isNotice = itemThree.isNotice == 0 ? false : true
              itemThree.isRegist = itemThree.isRegist == 0 ? false : true
            })
          })
        }
      })
      this.conList = JSON.parse(JSON.stringify(data))
      this.conListSource = JSON.parse(JSON.stringify(data))
    },
  }
};
</script>

<style lang="less" scoped>
.setting-container {
  .borderTop {
    border-top: 16px solid #eee;
  }
  .shop-web-platform {
    display: flex;
    align-items: center;
  }
  .header {
    box-sizing: border-box;
    padding: 25px 127px 32px 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 100);
    .con-title {
      display: flex;
      .tit-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 330px;
        .address {
          // margin-top: 23px;
          font-size: 22px;
        }
        .el-icon-location {
          font-size: 22px;
          margin-right: 8px;
          color: #1890ff;
        }
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f2f9ff;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tit-center {
        display: flex;
        .center-fir,
        .center-sec {
          display: flex;
          line-height: 42px;
          .center-fir-margin {
            margin-left: 72px;
          }
          .center-fir-third {
            // margin-right: 57px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 104px;
              display: inline-block;
              width: 1px;
              height: 72px;
              background: #bbb;
            }
          }
          .center-fir-con {
            display: inline-block;
            height: 42px;
          }
        }
        .sec-margin {
          margin-left: 110px;
        }
        .sec-width {
          width: 250px;
          text-align: left;
        }
        .center-sec-icon {
          margin-right: 16px;
        }
        .center-sec-con {
          display: inline-block;
          // margin-top: 23px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .step {
    margin-top: 48px;
  }
  .gap {
    height: 16px;
    background: #eee;
    margin: 32px 0;
  }
  .con-tit {
    display: flex;
    border-bottom: 1px solid rgba(181, 209, 255, 100);
    padding: 30px 134px;
    box-sizing: border-box;
  }
  .tit-index {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 28px;
    color: #fff;
    border-radius: 50%;
    background: #1890ff;
    margin-right: 34px;
  }
  .tit-img {
    width: 72px;
    height: 72px;
    margin: 0 20px 0 0;
    border-radius: 50%;
  }
  .tit-name {
    color: rgba(51, 51, 51, 100);
    font-size: 24px;
    margin-right: 40px;
  }
  .tit-tag {
    width: 80px;
    height: 27px;
    line-height: 27px;
    text-align: center;
  }
  .blue {
    border: #1890FF solid 1px !important;
    color: #1890FF;
    background: #f2f9ff;
  }
  .orange {
    border: #F09009 solid 1px !important;
    color: #F09009;
    background: #FFF8ED;
  }
  .shop-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .tit-de {
    margin-right: 32px;
    white-space: nowrap;
  }
  .website {
    font-size: 16px;
    max-width: 68%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tit-childrenNum {
    margin-left: 196px;
  }
  .tit-num {
    font-size: 28px;
  }
  .titHeader{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
  }
  .tit-child,
  .tit-child2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 78px;
    position: relative;
  }
  .tit-child2 {
    margin-right: 50px;
    &::after {
      position: absolute;
      top: 17px;
      right: -50px;
      content: "";
      display: inline-block;
      width: 1px;
      height: 64px;
      background: #bbbbbb;
    }
  }
  .tit-days {
    color: rgba(24, 144, 255, 100);
    font-size: 42px;
  }
  .tit-date {
    font-size: 14px;
  }
  .titDays {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 39px;
    margin-bottom: 20px;
  }
  .con-body {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 80px;
    padding: 30px 134px;
    box-sizing: border-box;
    .body-item-grey {
      border: 1px solid #ccc !important;
    }
    .body-item {
      width: 304px;
      height: 530px;
      border: 1px solid #1890ff;
      border-radius: 4px;
      margin-top: 40px;
      padding: 24px;
      .one-tit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .one-tit-header {
          font-size: 18px;
        }
      }
      .one-con {
        ul{
          li{
            text-align: left;
          }
        }
        .li-item{
          margin-top: 14px;
        }
        .li-one {
          display: inline-block;
          text-align: left;
          width: 120px;
        }
        .li-two {
          display: inline-block;
          text-align: center;
          width: 90px;
        }
        .twoColor{
          color: #1890ff;
        }
        .li-three {
          display: inline-block;
          text-align: center;
          width: 35px;
          margin-right: 20px;
        }
        .li-four{
          display: inline-block;
          text-align: center;
          width: 35px;
        }
      }
    }
  }
  .sixth{
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 84px;
    width: 100%;
    padding: 0 101px;
    box-sizing: border-box;
    border-top: 16px solid #eee;
    background: #fff;
    z-index: 999;
  }
  .sett-money{
    margin-right: 15px;
  }
  .sett-rmb{
    color: #1890FF;
    margin-right: 10px;
  }
  .sett-total{
    color: #1890FF;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status{
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return{
    border-color: #1890ff;
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0 0;
  }
}
</style>
