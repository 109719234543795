<!-- 头部&&步骤 -->
<template>
  <div class="child-container">
    <div class="header">
      <div class="con-title">
        <div class="tit-left">
          <div style="display: flex;align-items: center;">
            <span class="name common-over">{{ headerInfo.name || "--" }}</span
            ><span class="status">{{ headerInfo.levelName || "--" }}</span>
          </div>
          <span class="address common-over" ><i class="el-icon-location"></i>{{ headerInfo.areaName || "--" }}</span
          >
        </div>
        <div class="tit-center">
          <div class="center-fir">
            <div class="center-fir-margin">
              <span class="center-fir-con">电话</span><br /><span
                class="center-sec-con"
                >{{ headerInfo.phone || "--" }}</span
              >
            </div>
            <div class="center-fir-margin">
              <span class="center-fir-con">所属行业</span><br /><span
                class="center-sec-con"
                >{{ headerInfo.industryName || "--" }}</span
              >
            </div>
            <div class="center-fir-margin center-fir-third">
              <span class="center-fir-con">客户来源</span><br /><span
                class="center-sec-con"
                >{{ headerInfo.sourceName || "--" }}</span
              >
            </div>
          </div>
          <div class="center-sec sec-margin">
            <div style="display: flex;flex-direction: column;">
              <div class="sec-width">
                <span class="center-sec-icon"
                  ><img
                    src="../../../assets/images/wx.svg"
                    class="sicon"/></span
                ><span class="common-over">{{ headerInfo.wx || "--" }}</span>
              </div>
              <div class="sec-width">
                <span class="center-sec-icon"
                  ><img
                    src="../../../assets/images/mail.svg"
                    class="sicon"/></span
                ><span class="common-over">{{ headerInfo.mail || "--" }}</span>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;">
              <div class="sec-width">
                <span class="center-sec-icon"
                  ><img
                    src="../../../assets/images/qq.svg"
                    class="sicon"/></span
                ><span class="common-over">{{ headerInfo.qq || "--" }}</span>
              </div>
              <div class="sec-width">
                <span class="center-sec-icon">
                  <img src="../../../assets/images/web.svg" class="sicon"/>
                </span>
                <span class="common-over">{{ headerInfo.web || "--" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step">
      <el-steps :active="stepActive" align-center>
        <el-step title="基础服务填报" @click.native="() => { stepActive > 0 ? routeFun('base'):'' }" icon="el-icon-s-help"></el-step>
        <el-step title="配置服务内容" @click.native="() => { stepActive > 1 ? routeFun('setting'): '' }" icon="el-icon-s-help"></el-step>
        <el-step title="约定数据标准" @click.native="() => { stepActive > 2 ? routeFun('appoint'):'' }" icon="el-icon-s-help"></el-step>
        <el-step title="确认报价" @click.native="() => { stepActive > 3 ? routeFun('confirm'): '' }" icon="el-icon-s-help"></el-step>
      </el-steps>
      <div class="gap"></div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { cusInfo } from "../../../service/quoted.js";

export default {
  name: "HeaderArea",
  components: {},
  props: ["stepActive"],
  data() {
    return {
      headerInfo: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    routeFun (type) {
      if(type == 'base'){
        this.$router.push({path: '/quoted/base-services',query: {id: this.$route.query.id}})
      }else if(type == 'setting'){
        this.$router.push({path: '/quoted/setting-content',query: {id: this.$route.query.id}})
      }else if(type == 'appoint'){
        this.$router.push({path: '/quoted/appoint',query: {id: this.$route.query.id}})
      }else if(type == 'confirm'){
        this.$router.push({path: '/quoted/confirm',query: {id: this.$route.query.id}})
      }
    },
    async infoCus(id) {
      const { data } = await cusInfo({}, id);
      this.headerInfo = data;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.infoCus(this.$route.query.id);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.child-container{
  background: #fff;
}
.header {
  box-sizing: border-box;
  padding: 25px 127px 32px 100px;
  border-bottom: 1px solid rgba(217, 217, 217, 100);
  .con-title {
    display: flex;
    .tit-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // width: 330px;
      .address {
        margin-top: 2px;
        font-size: 16px;
        width: 280px;
        text-align: left;
        color: #333;
        line-height: 42px;
      }
      .el-icon-location {
        font-size: 22px;
        margin-right: 8px;
        color: #1890ff;
      }
      .name {
        font-size: 28px;
        width: 200px;
        text-align: left;
      }
      .status {
        box-sizing: border-box;
        background-color: #f2f9ff;
        color: #1890ff;
        border: 1px solid #1890ff;
        border-radius: 2px;
        padding: 4px 6px;
        margin-left: 24px;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .tit-center {
      display: flex;
      .center-fir,
      .center-sec {
        display: flex;
        line-height: 42px;
        .center-fir-margin {
          margin-left: 72px;
        }
        .center-fir-third {
          // margin-right: 57px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 6px;
            left: 104px;
            display: inline-block;
            width: 1px;
            height: 72px;
            background: #bbb;
          }
        }
        .center-fir-con {
          display: inline-block;
          height: 42px;
        }
      }
      .sec-margin {
        margin-left: 110px;
      }
      .sec-width {
        width: 320px;
        text-align: left;
        display: flex;
      }
      .center-sec-icon {
        margin-right: 16px;
      }
      .center-sec-con {
        display: inline-block;
        // margin-top: 23px;
        font-size: 20px;
        color: #333;
      }
    }
  }
}
.step {
  position: relative;
  box-sizing: border-box;
  // margin: 48px 0;
  padding: 48px 0;
  margin-bottom: 16px;
}
.sicon{
  vertical-align: middle;
  width: 24px;
}
/deep/ .el-step__icon.is-text{
  border: none;
}
/deep/.el-icon-s-help{
  font-size: 24px;
  // cursor: pointer;
}
.stepTit{
  font-size: 16px;
}
</style>
